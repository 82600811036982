import React, { useContext } from "react"

import CitationContext from "@src/contexts/CitationContext"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"

/**
 * Construct a CitationList structured content doc.
 *
 * @return {Object} CitationList structured content doc.
 */
const buildStructuredContentDoc = () => ({
  type: "Container",
  version: "1.0.0",
  props: {
    children: [
      {
        type: "Container",
        version: "1.0.0",
        props: {
          spacing: {
            top: 4,
          },
          children: [
            {
              type: "CitationList",
              version: "1.0.0",
              props: {},
            },
          ],
        },
      },
      {
        type: "Container",
        version: "1.0.0",
        props: {
          spacing: {
            top: 3,
            bottom: 4,
          },
          children: [
            {
              type: "BackToTopLink",
              version: "1.0.0",
              props: {},
            },
          ],
        },
      },
    ],
  },
})

const ArticleSources = () => {
  const contextData = useContext(CitationContext)

  // Does not render the "Back to top" link if no Citations are present.
  if (
    !(
      contextData?.inlineCitations?.length ||
      contextData?.extraCitations?.length
    )
  ) {
    return null
  }

  return (
    <StructuredContentRenderer>
      {buildStructuredContentDoc()}
    </StructuredContentRenderer>
  )
}

export default ArticleSources
