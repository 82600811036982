import React from "react"

import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import ArticleSources from "@src/components/ArticleSources"
import DefaultSeparator from "./_component_default_separator"
import { CitationContextProvider } from "@src/contexts/CitationContext"

import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import data from "@src/fixtures/Citation.1.0.0"

const CitationPage = () => {
  const title = "Citation Page"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
      footer={<Footer />}
    >
      <Columns>
        <Column width={{ desktop: 8, mobile: 12 }}>
          <DefaultSeparator />
          <CitationContextProvider>
            <StructuredContentRenderer>{data}</StructuredContentRenderer>
            <ArticleSources />
          </CitationContextProvider>
          <DefaultSeparator />
        </Column>
        <Column width={{ desktop: 4, mobile: 12 }}>[right rail]</Column>
      </Columns>
    </Page>
  )
}

export default CitationPage
